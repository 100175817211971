.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

.grid-wrapper {
  padding-top: 65px;
}

.parent-link span {
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 10px;
  transition: all .1s ease;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
}

.child-link span {
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 0;
  padding-left: 10px;
  transition: all .1s ease;
}


.sidebar-wrapper {
  background-color: #0f1321;
  color: #f0f0f0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

header.material-ui-app-bar {
  background-color: #024ddf;
}

body .has-bg {
  padding-top: 24px;
}

.has-bg h4 {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 24px;
  padding-left: 24px;
}

.multi-links {
  border-left: 2px solid #61affe;
  margin: 0px 0 14px 24px!important;
}

header img {
  margin-top: 12px;
}

.multi-links li {
  color: #fff;
  cursor: pointer;
}

.multi-links li:hover, .multi-links li:focus {
  color: #21FFF2;
}
.multi-links li.active a {
  color: #21FFF2;
}